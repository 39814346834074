(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adaptImage = adaptImage;
exports.autoChange = autoChange;
exports.autoPlaceholder = autoPlaceholder;
exports.capitalize = capitalize;
exports.consoleLog = consoleLog;
exports.deferLoadIMG = deferLoadIMG;
exports.encodeGETParams = encodeGETParams;
exports.formatPrice = formatPrice;
exports.getBrowserName = getBrowserName;
exports.getCookie = getCookie;
exports.getDeclension = getDeclension;
exports.getDimentions = getDimentions;
exports.getErrorLog = getErrorLog;
exports.getScrollbarWidth = getScrollbarWidth;
exports.getOptions = getOptions;
exports.getQueryState = getQueryState;
exports.getRandomInt = getRandomInt;
exports.getRemoteImages = getRemoteImages;
exports.parseGETParams = parseGETParams;
exports.priceToInt = priceToInt;
exports.refreshPage = refreshPage;
exports.setCookie = setCookie;
exports.toggleGif = toggleGif;
exports.transJS = transJS;
exports.translit = translit;
exports.truncateText = truncateText;

function adaptImage() {
  var src = $(this).attr('src');
  $(this).attr('src', $(this).attr('data-adaptive'));
  $(this).attr('data-adaptive', src);
  $(this).toggleClass('adapted');
  $(this).closest('.slick-slider').slick('refresh');
}

function autoChange(element) {
  if (!$(element).is(':hover')) {
    var $this = $(element),
        $inputs = $this.find('input');
    var ix = $inputs.index($inputs.filter(':checked'));
    if (ix < $inputs.length - 1) ix++;else ix = 0;
    $inputs.eq(ix).siblings('label').trigger('click');
  }

  return setTimeout(function () {
    window.loops[element.id] = autoChange(element);
  }, 3000);
}

function autoPlaceholder() {
  var replacePL = function replacePL() {
    $('img[data-pl]').each(function () {
      this.src = 'https://via.placeholder.com/' + (this.attributes.src.value || 500);
    });
  };

  document.body.addEventListener('error', function () {}, true);
  replacePL();
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function consoleLog(logItem) {
  if (window.debugMode) console.dir(logItem);
}

function deferLoadIMG() {
  $('img, video').each(function () {
    $(this).data('load-src', $(this).attr('src'));
    $(this).data('load-srcser', $(this).attr('srcset'));
    $(this).removeAttr('src');
    $(this).removeAttr('srcset');
    $(this).addClass('defload');
  });
  $(document).on('scroll', function (e) {
    $('.defload').each(function () {
      if ($(this).offset().top < $(document).scrollTop() + $(window).height() * 1.5) {
        $(this).attr('src', $(this).data('load-src'));
        $(this).attr('srcset', $(this).data('load-srcset'));
        $(this).removeClass('defload');
      }
    });
  });
}

function encodeGETParams(params) {
  return "?" + Object.entries(params).map(function (e) {
    return e.join('=');
  }).join('&');
}

function formatPrice() {
  var price = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return price.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}

function getBrowserName() {
  var ua = navigator.userAgent;
  var className;
  if (ua.search(/Chrome/) !== -1) className = 'br_google_chrome';
  if (ua.search(/Firefox/) !== -1) className = 'br_firefox';
  if (ua.search(/Opera/) !== -1) className = 'br_opera';
  if (ua.search(/Safari/) !== -1) className = 'br_safari';
  if (ua.search(/MSIE/) !== -1) className = 'br_internet_explorer';
  return className;
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }

  return "";
}

function getDeclension(count) {
  var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var endings = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  if (endings.length < 3) {
    return base;
  }

  count = count.toString();

  if (count.length > 2) {
    count = +count.slice(-2);
  }

  if (count >= 10 && count <= 20) {
    return base + endings[2];
  } else {
    if (count >= 10) count %= 10;

    switch (+count) {
      case 1:
        return base + endings[0];

      case 2:
      case 3:
      case 4:
        return base + endings[1];

      default:
        return base + endings[2];
    }
  }
}

function getDimentions() {
  window.wWidth = $(window).width();
  window.wHeight = $(window).height();
  window.isMobile = $("body").data("breakpoint") ? $(window).width() <= $("body").data("breakpoint") : $(window).width() < 768;
}

function getErrorLog(message) {
  return $('<div></div>').addClass('error-log').text(message);
}

function getScrollbarWidth() {
  var div = document.createElement('div');
  div.style.overflowY = 'scroll';
  div.style.visibility = 'hidden';
  document.body.append(div);
  var sw = div.offsetWidth - div.clientWidth;
  div.remove();
  return sw;
}

function getOptions() {
  if (!('get' in this.dataset) || !('url' in this.dataset)) return;

  var _this = this,
      $select = $(_this).closest('form').find("select[name=\"".concat(_this.dataset.target || _this.dataset.get, "\"]"));

  $(_this).closest('.row').addClass('loading');
  $.ajax({
    url: this.dataset.url,
    data: {
      get: this.dataset.get,
      filter: this.dataset.filter ? {
        name: this.dataset.filter,
        value: this.value
      } : null
    },
    dataType: 'json',
    type: 'post',
    success: function success(data) {
      if (typeof data === 'string') data = JSON.parse(data);
      $select.html(data.options.map(function (e) {
        return "<option value=\"".concat(e.ID, "\" ").concat(e.AUX || '', " ").concat(e.selected || '', " ").concat(e.disabled || '', ">").concat(e.NAME, "</option>");
      }));
      $select.trigger('change');
    },
    complete: function complete() {
      $(_this).closest('.row').removeClass('loading');
    }
  });
} //TODO Describe!


function getQueryState($submittable) {
  var params = $submittable.find("input:not([data-query-excluded])").serialize();
  var query = params ? "?".concat(params) : false;
  return query || location.origin + location.pathname;
}

function getRandomInt(from, to) {
  from = from || 0;
  to = to || 10;

  if (from > to) {
    return from;
  }

  return Math.floor(Math.random() * (to - from) + from);
}

function getRemoteImages(url, condition) {
  if (condition) {
    $('img').each(function () {
      $(this).attr('src', url + $(this).attr('src'));
    });
    $(document).on('error', 'img', function () {
      if (!$(this).hasClass('retried')) $(this).addClass('retried').attr('src', url + $(this).attr('src'));else console.error('Cannot retrieve image: ' + $(this).attr('src'));
    });
  }
}

function parseGETParams() {
  var params = {};
  window.location.search.replace(/\?/g, "").split("&").forEach(function (e) {
    var param = e.split('=');
    params[param[0]] = param[1];
  });
  return params;
}

function priceToInt(val) {
  return parseInt(val.toString().replace(/ /g, ""));
}

function refreshPage(time, redirect) {
  time *= 1000;
  if (!redirect) setTimeout(function () {
    location.reload();
  }, time);else setTimeout(function () {
    location.assign(redirect);
  }, time);
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

function toggleGif() {
  var $this = $(this).find('img');
  if (!$this.length || !$this.data('src')) return false;
  $this.on('load', function () {
    $(this).closest('.loading').removeClass('loading');
    $(this).off('load');
  });
  $(this).toggleClass('playing');
  $(this).addClass('loading');
  var tmpSrc = $this.attr('src');
  $this.attr('src', $this.attr('data-src'));
  $this.attr('data-src', tmpSrc);
}

function transJS(response, responseHandler) {
  eval('const data = ' + response + ";");
  responseHandler(data);
}

function translit(str) {
  var rus = "абвгдеёжзийклмнопрстуфхцчшщъыьэюя";
  var tr = ['a', 'b', 'v', 'g', 'd', 'e', 'yo', 'zh', 'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'kh', 'ts', 'tch', 'sh', 'sch', '', 'i', '', 'e', 'yu', 'ya'];
  return str.toLowerCase().split('').map(function (e, i) {
    if (e === ' ') return '_';
    if (rus.includes(e)) return tr[rus.indexOf(e)];
    if (e.includes(e)) return e;
    return '-';
  }).join('');
}

function truncateText($selector) {
  var truncateLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 30;
  if (!$selector) $selector = '.truncate';
  $selector = $($selector);
  $selector.each(function () {
    var length = +$(this).data('length') || truncateLength;
    var text = $(this).text().replace(/(\n|\s+|\t)/g, ' ');

    if (text.length - 3 > length) {
      $(this).text(text.slice(0, length) + '...');
      $(this).attr('title', text);
    }
  });
}

},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initAccordeonPlugin = initAccordeonPlugin;
exports.initAjaxFormPlugin = initAjaxFormPlugin;
exports.initCounterPlugin = initCounterPlugin;
exports.initDropDownPlugin = initDropDownPlugin;
exports.initMaskPlugin = initMaskPlugin;
exports.altPhoneMask = altPhoneMask;
exports.initModalPlugin = initModalPlugin;
exports.modalMsg = modalMsg;
exports.setModal = setModal;
exports.initOptionConfigurator = initOptionConfigurator;
exports.initParallaxPlugin = initParallaxPlugin;
exports.initScrollPlugin = initScrollPlugin;
exports.initSliderPlugin = initSliderPlugin;
exports.setSlider = setSlider;
exports.initStickyPlugin = initStickyPlugin;
exports.setSticky = setSticky;
exports.initTabsPlugin = initTabsPlugin;
exports.setToggleScroller = setToggleScroller;
exports.initPlugins = initPlugins;
exports.starsBG = starsBG;
exports.initVideoPlayer = initVideoPlayer;
exports.initMovetos = initMovetos;

var _functions = require("../functions");

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

//accordeon
function initAccordeonPlugin() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ".wrk.accordeon, .wrk-accordeon";
  $(document).on("click", ".trigger, .wrk-trigger", function (e) {
    e.preventDefault();
    var $this = $(this),
        $parent = $this.closest(selector),
        $target = $($this.data('target') || $this.attr('href'));
    if (!$target.length) return;

    if ($parent.hasClass('static')) {
      $target.css('height', $target.get(0).scrollHeight);
    }

    if ($parent.hasClass("excluding")) {
      $parent.find('.cont.active, .trigger.active').removeClass('active');
      $($this).addClass('active');
      $($target).addClass('active');
    } else {
      $($this).toggleClass('active');
      $($target).toggleClass('active');
    }
  });
} //ajax-forms


function initAjaxFormPlugin(ajaxFormHandler) {
  var selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.wrk.ajax-form';
  var urlPrefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  var urlPostfix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";

  $.prototype.setLoadingState = function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (this.is("[data-loadable]")) {
      var $loadable = !!this.data('loadable') ? this.find(this.data('loadable')) : this;
      if (!!$loadable.length) $loadable["".concat(state ? 'add' : 'remove', "Class")]('loading');
    }
  };

  $.prototype.disableSubmit = function () {
    this.setLoadingState();
    this.find('[type="submit"]').attr('disabled', 'disabled').prop('disabled', true);
  };

  $.prototype.enableSubmit = function () {
    this.setLoadingState(false);
    this.find('[type="submit"]').removeAttr('disabled').prop('disabled', false);
  };

  if ($(selector).length > 0) {
    $(document).on('submit', selector, function (e) {
      e.preventDefault(); // if ($(this).is('[data-captcha]')) {
      //     if (!this.dataset.captcha) return false;
      //     e.stopImmediatePropagation();
      //     if (!$(this).find('[name="gc-token"]').length) {
      //         const token = document.createElement('input');
      //         $(token).attr({
      //             name: 'gc-token',
      //             type: 'hidden',
      //             value: this.dataset.captcha
      //         });
      //         this.prepend(token);
      //     } else {
      //         $(this).find('[name="gc-token"]').val(this.dataset.captcha);
      //     }
      //     setTimeout((function () {
      //         this.dataset.captcha = '';
      //         this.find('[name="gc-token"]').remove();
      //     }).bind(this), 120000);
      // }

      var $this = $(this),
          data = $this.data(),
          formData = $this.attr("enctype") ? new FormData(this) : $this.serialize(),
          dataType = data.datatype || "json",
          method = $this.attr("method") || 'post',
          options = {
        data: formData,
        dataType: dataType,
        method: method,
        async: !!data.async,
        beforeSend: function beforeSend() {
          $this.disableSubmit();
          $this.find('.head-message, .error-log').removeClass('error success').html('');
        },
        success: function success(result) {
          if (!!data.handler && typeof window[data.handler] === 'function') {
            window[data.handler](result, $this);
          } else if (typeof ajaxFormHandler === 'function') {
            ajaxFormHandler(result, $this);
          } else {
            if (result.success) {
              if ($this.data('target') && $($this.data('target').length)) {
                $($this.data('target')).html(result.message);
              } else {
                var $message = $this.find('.head-message');
                $message.addClass('success').html(result.message);
                $this.html($message);
              }

              if (data.reload) {
                setTimeout(function () {
                  location.reload();
                }, +(data.reload || 0) * 1000);
              }
            } else {
              $this.find('.head-message').addClass('error').html(result.message || $this.data('error-message'));

              if ('errors' in result) {
                for (var name in result.errors) {
                  if (!result.errors.hasOwnProperty(name)) continue;
                  var $log = $this.find(".error-log[data-for=\"".concat(name, "\"]"));

                  if (!$log.length) {
                    $log = $('<div>').addClass('error-log').attr('data-for', name);
                    $this.find("[name=\"".concat(name, "\"]")).after($log);
                  }

                  $log.addClass('error').html(result.errors[name]);
                }
              }
            }
          }
        },
        error: function error(result) {
          if (window.debugMode) console.log("WRK_ERROR: " + result.responseText);
          $this.find('.head-message').addClass('error').html(result.message || data['error-message']);
        },
        complete: function complete() {
          $this.enableSubmit();

          if (!!$this.data('captcha') && (typeof grecaptcha === "undefined" ? "undefined" : _typeof(grecaptcha)) === 'object') {
            grecaptcha.reset(window.captchas[$this.data('captcha')]);
          }
        }
      };
      var url = $this.attr("action");
      if (data.customAction) url = data.customAction;else if ($this.attr("action")) url = urlPrefix + url + urlPostfix;

      if ($this.attr('enctype')) {
        options.cache = false;
        options.processData = false;
        options.contentType = false;
      }

      $.ajax(url, options);
      return false;
    });
    $(document).on('change', "".concat(selector, " [type=\"file\"].listable"), function (e) {
      var $target = $(this).closest('form').find($(this).data('list'));

      if ($target.length) {
        $target.html(Object.values(this.files).map(function (f) {
          return $("<div>").html(f.name);
        }));
      }
    });
  }
} //counters


function initCounterPlugin() {
  var dispatchEvent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  $(document).on('click', '.wrk.counter .inc, .wrk.counter .dec, .wrk-counter .inc, .wrk-counter.dec', function () {
    var counter = $(this).closest('.counter, .wrk-counter').find('.count').get(0);
    if (!counter) return;
    var prop = counter.tagName === 'INPUT' ? 'value' : 'innerHtml';
    var factor = this.classList.contains('inc') ? 1 : -1;
    if ((counter.min || counter.dataset.min) === counter[prop] && factor < 0) return;
    counter[prop] = +counter[prop] + factor;

    if (dispatchEvent) {
      var event = new Event("input", {
        bubbles: true
      });
      var tracker = counter._valueTracker;

      if (tracker) {
        tracker.setValue(counter.value);
      }

      counter.dispatchEvent(event);
    }
  });
  $(document).on('change', '.wrk.counter input, .wrk-counter input', function () {
    var min = this.min || this.dataset.min;
    if (!!min && this.value < min) this.value = min;
  });
} //dropdowns


function initDropDownPlugin() {
  var resetHandler = function resetHandler() {
    $(this).closest('.dropdown').removeClass('applied').find(':checked').removeAttr('checked').prop('checked', false);
    $(this).closest('.dropdown').find('[data-default]').each(function () {
      switch (this.tagName) {
        case 'INPUT':
          $(this).prop('checked', true);
          break;

        case 'OPTION':
        case 'SELECT':
          $(this).prop('selected', true);
          break;

        default:
          $(this).addClass('.active');
      }
    });
    $(this).closest('form').trigger('submit');
  };

  if ($('.wrk.dropdown, .wrk-dropdown').length) {
    $(document).on('click', function (e) {
      var $this = e.target.classList.contains('dropdown') ? $(e.target) : $(e.target).closest('.dropdown');

      if (!$this.length) {
        $('.dropdown.open').removeClass('open');
        return;
      }

      var $options = $this.find('.options');
      if (e.target === $this.get(0)) $this.toggleClass('open');
      $('.dropdown.open').not($this).removeClass('open');

      if ($this.hasClass('open')) {
        if ($(window).width() > 575) {
          if ($this.get(0).getBoundingClientRect().left > $(window).width() / 2) {
            $options.css({
              right: 0
            });
            $options.find('.pick').css({
              right: 0
            });
          } else {
            $options.css({
              left: 0
            });
            $options.find('.pick').css({
              left: 0
            });
          }
        } else {
          $options.css({
            left: "calc(100% - ".concat($options.outerWidth(true), "px)")
          });
          $options.find('.pick').css({
            right: 0,
            left: 0,
            marginLeft: 'auto',
            marginRight: 'auto'
          });
        }
      } else {
        $options.removeAttr('style');
        $options.find('.pick').removeAttr('style');
      }
    });
    $(document).on('click', '.dropdown .reset', resetHandler);
    $(document).on('click', '.dropdown .apply', function () {
      var $dropdown = $(this).closest('.dropdown');
      var applied = [];
      $dropdown.find(':checked, :selected, .active').each(function () {
        !!$(this).attr('data-caption') && applied.push($(this).attr('data-caption'));
      });

      if (!applied.length) {
        $dropdown.removeClass('open');
        resetHandler.apply(this);
        return;
      }

      var val = applied.length > 1 ? applied.length : applied[0];
      $dropdown.data('applied-text') ? $dropdown.attr('data-applied-caption', $dropdown.data('applied-text') + val) : $dropdown.attr('data-applied-caption', val);
      $dropdown.removeClass('open').addClass('applied');
    });
  }
} //mask


function initMaskPlugin() {
  $('.wrk.masked-phone, .wrk-masked-phone').mask("8-000-000-0000");
  $('.wrk.masked-date, .wrk-masked-date').mask("00.00.0000");
  $('.wrk.masked-sdate, .wrk-masked-sdate').mask("00.00.00");
  $('.wrk.masked-datetime, .wrk-masked-datetime').mask("00.00.0000 00:00:00");
  $('.wrk.masked-phone-simple, .wrk-masked-phone-simple').mask("00-00-00");
  $('.wrk.masked-phone-mobile, .wrk-masked-phone-mobile').mask("+7 (000) 000-00-00");
  $.applyDataMask();
}

function altPhoneMask() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.wrk.masked-phone-alt';
  var $selector = typeof selector === 'string' ? $(selector) : selector;
  var options = {
    onKeyPress: function onKeyPress(cep, e, field, options) {
      var masks = ['+7 (000) 000-00-00', '00-00-00'];
      var mask = cep.length > 6 ? masks[1] : masks[0];
      options.placeholder = cep.length > 6 ? "+7 (___) ___-__-__" : "__-__-__";
      $selector.mask(mask, options);
    }
  };
  $selector.mask('+7 (000) 000-00-00', options);
} //modal


function initModalPlugin() {
  var defaultSettings = {
    closeBtn: false,
    padding: 0,
    margin: 0,
    wrapCSS: "modal-customized"
  },
      modalSets = {};
  $('.wrk.modal, .wrk-modal').not('[rel]').each(function () {
    var $this = $(this),
        customSets = $this.data("settings"),
        settings = $.extend({}, defaultSettings);
    if (customSets === "default") $this.fancybox();else if (typeof customSets !== 'undefined') {
      if (customSets in modalSets) {
        $this.fancybox(modalSets[customSets]);
      } else {
        $.ajax({
          url: customSets + ".json",
          dataType: 'json',
          async: false,
          dataFilter: function dataFilter(result) {
            result = typeof result === "string" ? JSON.parse(result) : result;

            for (var sets in result) {
              if (!result.hasOwnProperty(sets)) continue;

              if (sets.indexOf("eval:") > -1) {
                sets = sets.replace(/eval:/, "");
                if (window.hasOwnProperty(result[sets])) settings[sets] = window[result[sets]];
              } else {
                settings[sets] = result[sets];
              }
            }

            modalSets[customSets] = $.extend({}, settings);
          },
          success: function success() {
            $this.fancybox(modalSets[customSets]);
          },
          error: function error(result) {
            $this.fancybox(settings);
            if (window.debugMode) console.log("WRK_ERROR: " + result.responseText);
          }
        });
      }
    } else $this.fancybox(settings);
  });

  if ($('.wrk.modal[rel], .wrk-modal[rel]').length) {
    $('.wrk.modal[rel], .wrk-modal[rel]').fancybox(defaultSettings);
  }

  $(document).on('click', '.wrk.modal-dismiss, .wrk-modal-dismiss', function () {
    $.fancybox.close();
  });
}

function modalMsg() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    showTime: 0
  };

  if ($('#modal-msg.wrk').length > 0) {
    var caption = data.caption ? data.caption : data.status === "success" ? "Успешно!" : "Ошибка!";
    $(".modal-caption.wrk, .modal-message.wrk").html("");
    $(".modal-caption.wrk").html(caption);
    $(".modal-message.wrk").html(data.message);
    $.fancybox({
      "href": "#modal-msg",
      closeBtn: false,
      minWidth: 300
    });

    if (data.showTime > 0) {
      setTimeout(function () {
        if (data.redirect) location.href = data.redirect;else if (data.reload) location.reload();else $.fancybox.close();
      }, data.showTime);
    }
  }
}

function setModal() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.modal-trigger';
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var defSettings = {
    baseClass: 'wrk-modal',
    src: $(selector).attr('href'),
    smallBtn: false,
    buttons: false,
    arrows: false,
    defaultType: 'html',
    image: {
      preload: true
    }
  };
  if (!settings) settings = defSettings;else settings = Object.assign(defSettings, settings);
  $(document).on('click', selector, function (e) {
    e.preventDefault();
    $.fancybox.open(settings);
  });
} //option configurator


function initOptionConfigurator() {
  $(document).on('click', '.wrk .oc-option', function (e) {
    e.preventDefault();

    if ($(this).hasClass('active')) {
      return false;
    }

    var $this = $(this),
        $conf = $this.closest('.wrk.opt-conf, .wrk-opt-conf'),
        $cont = $conf.find('.oc-cont, .wrk-oc-cont'),
        $opts = $conf.find('.oc-options, .wrk-oc-options'),
        option = "[data-option=\"".concat($this.data('option'), "\"]"),
        module = $this.data('module') ? "[data-module=\"".concat($this.data('module'), "\"]") : '';
    $opts.find(module + '[data-option].active').removeClass('active');
    $cont.find(module + '.active').removeClass('active');
    $cont.find(module + option).addClass('active');
    $this.addClass('active');
  });
} //parallax


function initParallaxPlugin() {
  function parallax(pos) {
    $('.mid-layer, .wrk-mid-layer').css({
      transform: "translate(0, -" + pos * 0.5 + "px)"
    });
    $('.back-layer, .wrk-back-layer').css({
      transform: "translate(0, -" + pos * 0.2 + "px)"
    });
  }

  if ($('.wrk.parallax, .wrk-parallax').length > 0) {
    $('.wrk.parallax, .wrk-parallax').on('resize', function () {
      $('.mid-layer').css({
        height: document.body.scrollHeight * 1.5 + 'px'
      });
      $('.back-layer').css({
        height: document.body.scrollHeight * 1.2 + 'px'
      });
    }).trigger('resize');
    $(document).on('scroll', function () {
      parallax($(this).scrollTop());
    });
  }
} //scroll


function initScrollPlugin() {
  $(document).on('click', '.wrk.scroller, .wrk-scroller', function (e) {
    e.preventDefault();
    var scrollEl = $(this).attr('href');

    if ($(scrollEl).length) {
      $('html, body').animate({
        scrollTop: $(scrollEl).offset().top
      }, 600);
    }

    return false;
  });
} //slider


function initSliderPlugin() {
  var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var $selector = $(settings.selector || '.wrk.slider, .wrk-slider');
  var defaultSettings = {
    //default values:
    // accessibility: true,    //true
    adaptiveHeight: true,
    //false
    autoplay: true,
    //false
    autoplaySpeed: 5000,
    //3000
    arrows: true,
    //true
    //asNavFor: null, //null
    // appendArrows: $(element).find('.slider-controls'),   //element
    // appendDots: $(element).find('.slider-dots'),   //element
    //prevArrow: $(element),  //element
    //nextArrow: $(element),  //element
    //centerMode: false, //false
    //centerPadding: '50px',  //50px
    //cssEase: 'ease',    //ease
    //customPaging: null,  //n/a
    dots: true //false
    // draggable: true,    //true
    //fade: false,    //false
    //focusOnSelect: false,   //false
    //easing: 'linear',   //linear
    //edgeFriction: 0.15, //0.15
    // infinite: true, //true
    //initialSlide: 0,    //0
    //lazyLoad: 'ondemand',   //ondemand (progressive)
    //mobileFirst: false, //false
    //pauseOnHover: true, //true
    //pauseOnDotsHover: false,    //false
    //respondTo: 'window',    //window
    //responsive: null,   //none
    //rows: 1,    //1
    //slide: '', //''
    //slidesPerRow: 1,    //1
    //slidesToShow: 1,    //1
    //slidesToScroll: 1,  //1
    //speed: 300, //300
    //swipe: true,    //true
    //swipeToSlide: false, //false
    //touchMove: true,    //true
    //touchThreshold: 5, //5
    //useCSS: true, //true
    //variableWidth: false, //false
    //vertical: false, //false
    //verticalSwiping: false, //false
    //rtl: false //false,

  };
  $selector.each(function () {
    var $slider = $(this).find('.slider-container');
    var classPrefix = $(this).data('compatible') ? 'wrk-' : '';

    if (_typeof(settings) === 'object') {
      settings = $.extend({}, defaultSettings);
    } else {
      settings = defaultSettings;
    }

    if ($(this).data('settings')) {
      var sets = $(this).data('settings');

      if (typeof sets === 'string') {
        sets = JSON.parse(sets);
      }

      for (var s in sets) {
        if (!sets.hasOwnProperty(s)) {
          continue;
        }

        settings[s] = sets[s];
      }
    }

    if (!$(this).hasClass('slave')) {
      if (settings.arrows === true) {
        var $controls = $(this).find('.slider-controls');

        if (!$controls.length) {
          $controls = $('<div>').addClass(classPrefix + 'slider-controls');
          $(this).append($controls);
        }

        var $prev = $(this).find('.' + classPrefix + 'prev');
        var $next = $(this).find('.' + classPrefix + 'next');

        if (!$prev.length || !$next.length) {
          if (!$prev.length) $prev = $('<div>').addClass(classPrefix + 'prev');
          if (!$next.length) $next = $('<div>').addClass(classPrefix + 'next');
          $controls.append([$prev, $next]);
        }

        settings.prevArrow = $prev;
        settings.nextArrow = $next;
      }

      if (settings.dots === true) {
        if (!settings.appendDots) {
          $(this).append($('<div>').addClass(classPrefix + 'slider-dots'));
          settings.appendDots = $(this).find('.' + classPrefix + 'slider-dots');
          settings.dotsClass = classPrefix + 'slider-dots-list';
        } else {
          var $appendable = $(this).find(settings.appendDots);
          if ($appendable.length) settings.appendDots = $appendable;
          settings.dotsClass = classPrefix + 'slider-dots';
        }
      }
    }

    try {
      if (!!$(this).data("events")) {
        var events = $(this).data("events");

        if (typeof events === 'string') {
          events = JSON.parse(events);
        }

        for (var event in events) {
          if (!events.hasOwnProperty(event)) {
            continue;
          }

          switch (event) {
            case "init":
              events[event]($slider);
              break;

            default:
              $slider.on(event, events[event].bind(this));
          }
        }
      }
    } catch (e) {
      console.error('WRK Slider: cannot retrieve events: ' + e);
    }

    if ($(this).hasClass('master')) {
      settings.asNavFor = (settings.asNavFor || '.' + classPrefix + 'slider.slave') + ' .slider-container';
      $slider.slick(settings);
    } else {
      if ($(this).hasClass('slave')) {
        settings.arrows = false;
        settings.dots = false;
      }

      $slider.slick(settings);
    }
  });
}

function setSlider() {
  var $slider = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : $('.wrk.slider, .wrk-slider');
  var prefs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var init = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
  prefs = Object.assign({
    autoplay: false,
    adaptiveHeight: true,
    prevArrow: $slider.find('.prev'),
    nextArrow: $slider.find('.next'),
    easing: 'ease-out',
    dots: true,
    appendDots: $slider.find('.slider-dots')
  }, prefs || {});
  $slider.find('.slider-container').on({
    init: typeof init === 'function' ? init : null,
    reInit: typeof init === 'function' ? init : null
  }).slick(prefs);
  return $slider.find('.slider-container');
} //sticky


function initStickyPlugin() {
  $(".wrk.sticky, .wrk-sticky").sticky({
    topSpacing: 0
  });
}

function setSticky() {
  var $selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.sticky';
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    topSpacing: 0
  };
  if (typeof $selector === 'string') $selector = $($selector);
  $selector.sticky(settings);
} //tabs


function initTabsPlugin() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.wrk .tab:not(.unwrk), .wrk-tab';
  $(document).on('click', selector, function (e) {
    e.preventDefault();
    var $this = $(this),
        $cont = $this.closest('.tabs, .wrk-tabs'),
        $content = $cont.find('.tabs-cont, .wrk-tabs-cont');
    $cont.find('.tab.active, [data-tab].active').removeClass('active');
    $this.addClass('active');
    $content.find("[data-tab=\"".concat($this.data('tab') || $this.attr('href'), "\"]")).addClass('active');
  });

  var playNext = function playNext($player) {
    setTimeout(function () {
      var $tabs = $player.find('.tab');
      var index = $tabs.index($tabs.filter('.active').get(0));
      index = index === $tabs.length - 1 ? 0 : index + 1;
      $tabs.eq(index).trigger('click');
      playNext($player);
    }, $player.data('autoplay') || 5000);
  };

  $('.wrk.tabs[data-autoplay], .wrk-tabs[data-autoplay]').each(function () {
    playNext($(this));
  });
} //text-scroller


function setToggleScroller() {
  var $selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.wrk.text-scroll, .wrk-text-scroller';
  $(document).on('click', $selector + ' .dismiss', function () {
    $(this).closest($selector).fadeOut(500);
  });
  $selector = $($selector);
  $selector.fadeIn(500);
  var REM = parseInt(getComputedStyle(document.body).fontSize || 16) * 0.5;
  var $content = $selector.find('.content');
  var scHeight = $content[0].scrollHeight;
  var time = Math.ceil((scHeight - $content[0].clientHeight) / REM) * 1000;

  var scrollText = function scrollText() {
    $content.animate({
      scrollTop: scHeight
    }, time, function () {
      $content.animate({
        scrollTop: 0
      }, 1000, scrollText);
    });
  };

  scrollText();
} //init


function initPlugins() {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'meta[name="plugins"]';
  var plugins = null;

  if ($(selector).length) {
    plugins = $(selector).attr("content").split(";");
    if (!plugins[plugins.length - 1]) plugins.pop();
  }

  if (!!plugins) {
    for (var i = 0; i < plugins.length; i++) {
      switch (plugins[i]) {
        case "accordeon":
          initAccordeonPlugin();
          break;

        case "ajax-form":
          initAjaxFormPlugin();
          break;

        case "configurator":
          initOptionConfigurator();
          break;

        case "counter":
          initCounterPlugin();
          break;

        case "dropdown":
          initDropDownPlugin();
          break;

        case "mask":
          initMaskPlugin();
          break;

        case "modal":
          initModalPlugin();
          break;

        case "parallax":
          initParallaxPlugin();
          break;

        case "scroller":
          initScrollPlugin();
          break;

        case "slider":
          initSliderPlugin();
          break;

        case "sticky":
          initStickyPlugin();
          break;

        case 'tabs':
          initTabsPlugin();
          break;

        default:
          console.error("No such plugin can be included: ".concat(plugins[i], ". Check the validity of the name."));
      }
    }
  }
} //Under development...


function starsBG($selector) {
  if (!$selector || !$selector.length) return;
  var count = $selector.data('count') || 10,
      $container = $('<div>').addClass('stars'),
      pos = {
    x: (0, _functions.getRandomInt)(0, $selector.width()),
    y: (0, _functions.getRandomInt)(0, $selector.height())
  },
      stars = [],
      hW = $selector.width() / 2,
      hH = $selector.height() / 100 * ($selector.data('gradient') || 100) / 2;
  var delay = 0,
      f = 1;

  for (var i = 0; i < count; i++) {
    var $star = $('<span>').css({
      left: pos.x + 'px',
      top: pos.y + 'px',
      animationDelay: delay + 'ms'
    });
    var x = [0, hW * 2]; //pos.x >= hW ? [0, hW] : [hW, hW*2];

    var y = [0, hH * 2]; //pos.y >= hH ? [0, hH] : [hW, hH*2];

    stars.push($star);
    pos.x = _functions.getRandomInt.apply(void 0, x);
    pos.y = _functions.getRandomInt.apply(void 0, y);
    delay += 29 + (f += 1.5);
  }

  $selector.append($container.append($('<div>').addClass('canvas').append(stars)));
}

function initVideoPlayer() {
  $(document).on('click', '.wrk.video-player .option, .wrk-videoplayer .option', function (e) {
    if (e.currentTarget.tagName === 'A') e.preventDefault();
    var src = $(this).attr('href') || this.dataset.src;
    var $player = $(this).closest('.video-player, .wrk-video-player');
    if (!src || src[0] === '#' || $player.attr('src') === src) return;
    var poster = this.dataset.poster;
    $player.find('.option.active').removeClass('active');
    $(this).addClass('active');
    $player.find('video').attr({
      src: src,
      poster: poster || ''
    });
  });
}

function initMovetos() {
  $('[data-moveto]').each(function () {
    if (!$(this).data('moveto')) return;
    $($(this).data('moveto')).append($(this));
  });
}

},{"../functions":1}],3:[function(require,module,exports){
"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var wrk = _interopRequireWildcard(require("../../common/scripts/wrk/"));

var _functions = require("../../common/scripts/functions");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

$(function () {
  wrk.initMovetos();
  wrk.initPlugins(); // setTimeout(function(){
  //     const $helpers = $('#helpers a');
  //     $helpers.addClass('active');
  //     setTimeout(function () {
  //         $helpers.removeClass('active');
  //     }, 5000);
  // }, 5000);

  $('.options-getter').on('change', _functions.getOptions); // $('#order').find('[name="store"]').on('change', function() {
  //     $('#order').find('.show-map').attr('data-coords', $(this).find(':selected').data('coords') || "");
  // });

  $('#cities-list, .city-changer').find('input').on('change', function () {
    var $form = $(this).closest('form');

    if (!$form.hasClass('submitted')) {
      $form.addClass('submitted');
      $form.trigger('submit');
    }
  });
  $('.infolayer-toggle').on('click', function (e) {
    e.preventDefault();

    if ($(this).hasClass('dismiss') && $('body').hasClass('infolayered')) {
      $('.infolayer').addClass('hide');
      $('body').removeClass('infolayered');
    } else {
      $($(this).attr('href')).toggleClass('hide');

      if (!!$('body').find('.infolayer:not(.hide)').length) {
        $('body').addClass('infolayered');
      } else {
        $('body').removeClass('infolayered');
      }
    }
  });
  $('.wrk.tabs[data-autoplay] .tab').on('click', function () {
    var index = $(this).closest('.tabs-list').find('.tab').index(this);
    $(this).closest('.tabs-list').find('.cursor').css('transform', "translatex(".concat(100 * index, "%)"));
  });
  $('.cat-filter').on('submit', function (e) {
    e.preventDefault();
    var $this = $(this);
    var $target = $($this.data('target') || '#cat-list');
    history.replaceState(null, '', (0, _functions.getQueryState)($this));
    $.ajax(location.pathname, {
      data: $this.serialize(),
      dataType: 'html',
      type: 'get',
      beforeSend: function beforeSend() {
        $this.addClass('loading');
      },
      success: function success(data) {
        $target.html(data);
      },
      complete: function complete() {
        $this.removeClass('loading');
      }
    });
  });
  $('.slider.countable').find('.slider-container').on('beforeChange', function (e, $slider, current, next) {
    var $counter = $(this).closest('.slider').find('.counter');
    $counter.attr({
      'data-current': (next + 1).toString().padStart(2, '0'),
      'data-total': $slider.sliderCount
    });
  });
  $(document).on('click', '.wrk.tabs .tab', function (e) {
    var $activeContSliders = $(this).closest('.tabs').find('.tabs-cont .active').find('.wrk.slider');
    if ($activeContSliders.length) $activeContSliders.find('.slider-container').slick('refresh');
  });
  $(document).on('click', '[href="#"]', function (e) {
    e.preventDefault();
    alert('Функционал недоступен в демо-режиме.');
  });
});

},{"../../common/scripts/functions":1,"../../common/scripts/wrk/":2}]},{},[3]);
